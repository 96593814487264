import React, { useRef } from 'react';
import { Page, Text, View, Document, pdf } from '@react-pdf/renderer';
import { reportStyles } from './reportGenerationStyles';
import { Button } from 'antd';
import dayjs from 'dayjs';
import {
  formatReportItem,
  calculateTotalMinutes,
  detailedReportHeaders,
  getSummarizedHeaders,
  getSortLabel,
  escapeSpecialChars,
  calculateTotalMinutesWithoutBreak,
} from '../../utils/reportsHelpers';
import { convertTimeToDecimal, minutesToTimeString, timeStringToMinutes ,decimalToTime} from '../../utils';
import { PrinterOutlined } from '@ant-design/icons';

const PrepareNonSummarize2PDF = (serialized_reports, groupedReports, subTitle) => {
  serialized_reports.forEach(({ key, reports, totalDuration, summary }) => {
    if (!groupedReports[key]) {
      groupedReports[key] = {};
    }
    if (!groupedReports[key][subTitle]) {
      groupedReports[key][subTitle] = {
        reports: [],
        totalDuration: 0,
        summary: summary,
      };
    }

    const reportsWithSubTitle = reports.map((report) => ({ ...report, project_name: subTitle }));
    groupedReports[key][subTitle].reports.push(...reportsWithSubTitle);

    groupedReports[key][subTitle].totalDuration += timeStringToMinutes(totalDuration) || 0;
  });
};

const PrepareSummarize2PDF = (serialized_reports, groupedReports, project_name) => {
  serialized_reports.forEach(({ key, totalDuration }) => {
    if (!groupedReports[project_name]) {
      groupedReports[project_name] = {};
    }
    if (!groupedReports[project_name][key]) {
      groupedReports[project_name][key] = {
        totalDuration: 0,
      };
    }
    groupedReports[project_name][key].totalDuration += timeStringToMinutes(totalDuration) || 0;
  });
};

const groupReportsByKeyAndProject = (reports) => {
  const groupedReports = {};
  let isSummarized = false;

  reports.forEach(({ project_name, serialized_reports, is_summarized, department_name }) => {
    isSummarized = is_summarized;

    !isSummarized
      ? PrepareNonSummarize2PDF(
          serialized_reports,
          groupedReports,
          project_name ? project_name : department_name
        )
      : PrepareSummarize2PDF(serialized_reports, groupedReports, project_name);
  });

  return { groupedReports: groupedReports, isSummarized: isSummarized };
};

const renderNotSummarizedPage = (groupedReportsForKey, index, headers) => {
  if (groupedReportsForKey === undefined) {
    return null;
  }
  return Object.entries(groupedReportsForKey).map(([project_name, { reports, summary }], subIndex) => {
    const mappedReports = reports
      .map((report) => formatReportItem(report))
      .sort((a, b) => dayjs(a.in_time).diff(dayjs(b.in_time)));

    const totalMinutes = calculateTotalMinutes(mappedReports);
    const totalMinutesWithoutBreaks  = calculateTotalMinutesWithoutBreak(mappedReports);
    const footerData = [
      {
        sorted_key: 'סה"כ שעות',
        totalDuration: minutesToTimeString(totalMinutes),
        totalDurationWithoutBreaks:decimalToTime(totalMinutesWithoutBreaks),
      }
    ];

    return (
      <View key={`${index}-${subIndex}`}>
        <Text style={reportStyles.subHeader}>{project_name}</Text>
        <View style={reportStyles.table}>
          <View style={reportStyles.tableRow}>
            {headers.map((header, idx) => (
              <View
                style={reportStyles.tableColHeader}
                key={idx}
              >
                <Text style={reportStyles.tableCellHeader}>{header.label}</Text>
              </View>
            ))}
          </View>
          {mappedReports.map((report, idx) => (
            <View
              style={reportStyles.tableRow}
              key={idx}
            >
              {headers.map((header, colIdx) => (
                <View
                  style={reportStyles.tableCol}
                  key={colIdx}
                >
                  <Text style={reportStyles.tableCell}>{escapeSpecialChars(report[header.key])}</Text>
                </View>
              ))}
            </View>
          ))}
          <View style={reportStyles.tableRow}>
            {footerData.map((footer, idx) => (
              <View
                style={reportStyles.tableColSummarized}
                key={idx}
              >
                <Text style={reportStyles.tableCellSummarized}>
                  {`סה"כ שעות: ${convertTimeToDecimal(footer.totalDuration)}`}
                </Text>
                <Text style={reportStyles.tableCellSummarized}>
                  {`סה"כ שעות ללא הפסקות: ${footer.totalDurationWithoutBreaks}`}
                </Text>
                {summary &&
                  summary.map((item) => (
                    <Text style={reportStyles.tableCellSummarized}>
                      {`${item.activity_name}: ${item.total_days}`}
                    </Text>
                  ))}
              </View>
            ))}
          </View>
        </View>
      </View>
    );
  });
};

const renderSummarizedPage = (groupedReportsForKey, index, headers) => {
  if (groupedReportsForKey === undefined) {
    return null;
  }

  const mappedSummarizedReports = Object.entries(groupedReportsForKey).map(([key, { totalDuration }]) => {
    return { sorted_key: key, totalDuration: minutesToTimeString(totalDuration) };
  });

  const totalMinutes = calculateTotalMinutes(mappedSummarizedReports);
    
  const footerData = [
    {
      sorted_key: 'סה"כ',
      totalDuration: minutesToTimeString(totalMinutes),
    },
    
  ];

  const mappedReportsRenderer = mappedSummarizedReports.map((report, index) => {
    return (
      <View
        style={reportStyles.tableRow}
        key={index}
      >
        {headers.map((header, colIdx) => (
          <View
            style={reportStyles.tableColSummarized}
            key={colIdx}
          >
            <Text style={reportStyles.tableCell}>{report[header.key]}</Text>
          </View>
        ))}
      </View>
    );
  });

  return (
    <View key={`${index}`}>
      <View style={reportStyles.table}>
        <View style={reportStyles.tableRow}>
          {headers.map((header, idx) => (
            <View
              style={reportStyles.tableColSummarizedHeader}
              key={idx}
            >
              <Text style={reportStyles.tableCellHeader}>{header.label}</Text>
            </View>
          ))}
        </View>
        {mappedReportsRenderer}
        <View style={reportStyles.tableRow}>
          {footerData.map((footer, idx) => (
            <View
              style={reportStyles.tableColSummarized}
              key={idx}
            >
              <Text style={reportStyles.tableCellSummarized}>{` סה"כ שעות ${footer.totalDuration}`}</Text>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
};

const renderTablesForKey = (groupedReportsForKey, isSummarized, sortingOption, index, key) => {
  const headers = isSummarized ? getSummarizedHeaders(sortingOption) : detailedReportHeaders;

  return (
    <Page
      key={index}
      style={reportStyles.page}
    >
      {!isSummarized ? (
        <Text style={reportStyles.header}>
          {sortingOption === 'unfiltered' ? key : `${getSortLabel(sortingOption)}: ${key}`}
        </Text>
      ) : (
        <Text style={reportStyles.header}>{`רשימה מקוצרת עבור פרויקט ${key}`}</Text>
      )}

      {!isSummarized
        ? renderNotSummarizedPage(groupedReportsForKey, index, headers)
        : renderSummarizedPage(groupedReportsForKey, index, headers)}
    </Page>
  );
};

const ReportDocPDF = ({ reports, sortingOption }) => {
  const reports2Print = groupReportsByKeyAndProject(reports.reportItems);

  return (
    <Document>
      {Object.entries(reports2Print.groupedReports).map(([key, groupedReportsForKey], index) =>
        renderTablesForKey(groupedReportsForKey, reports2Print.isSummarized, sortingOption, index, key)
      )}
    </Document>
  );
};

const generatePdfBlob = async (pdfDocumentComponent) => {
  return await pdf(pdfDocumentComponent).toBlob();
};

const ProcessAndPrintReportButton = ({ reports, sortingOption }) => {
  const iframeRef = useRef();
  const handlePrint = async () => {
    const blob = await generatePdfBlob(
      <ReportDocPDF
        reports={reports}
        sortingOption={sortingOption}
      />
    );

    const url = URL.createObjectURL(blob);
    const iframe = iframeRef.current;

    if (iframe) {
      iframe.src = url;
      iframe.onload = () => {
        iframe.contentWindow.print();
      };
    }
  };

  return (
    <>
      <Button
        onClick={handlePrint}
        icon={<PrinterOutlined />}
      >
        הדפסה
      </Button>
      <iframe
        ref={iframeRef}
        style={{ display: 'none' }}
        title='PDF Print Frame'
      />
    </>
  );
};

export default ProcessAndPrintReportButton;
