import dayjs from 'dayjs';
import { calculateDiffTime, convertTimeToDecimal, timeStringToMinutes } from '.';

// Utility function to escape special characters in a string
export const escapeSpecialChars = (str) => {
  if (!str) return str;

  // Replace commas with a space and leave double quotes as they are
  let escapedStr = str
    .replace(/,/g, ' ') // Replace commas with a space
    .replace(/\t/g, ' ') // Replace tabs with a space
    .replace(/\\/g, ' ') // Replace backslashes with a space
    .replace(/\n/g, ' '); // Replace newlines with a space

  // No need to wrap in quotes since you're not dealing with commas or special characters
  return escapedStr;
};

// Format a detailed report item
export const formatReportItem = ({ in_time, out_time, user, activity, project, comments, file }) => ({
  reportDate: dayjs(in_time).format('DD/MM/YYYY [יום] ddd'),
  name: `${user.first_name} ${user.last_name}`,
  projectName: project.project_name,
  activity: activity.activity_name,
  start_time: dayjs(in_time).format('HH:mm'),
  end_time: dayjs(out_time).format('HH:mm'),
  totalDuration: calculateDiffTime(in_time, out_time).format('H:mm'),
  totalDurationDecimal: convertTimeToDecimal(calculateDiffTime(in_time, out_time).format('H:mm')),

  description: comments,
  file: file ? 'כן' : 'לא',
});

// Calculate the total minutes from a list of reports
export const calculateTotalMinutes = (reports) =>
  reports.reduce((acc, item) => {
       return acc + timeStringToMinutes(item.totalDuration);
  }, 0);


  // Calculate the total minutes from a list of reports
 export const groupedDataByDate  = (reports) =>

  
  reports.reduce((acc, item) => {
       
    if (!acc[item.reportDate]) {
      acc[item.reportDate] = 0;
    }
    acc[item.reportDate] += parseFloat(item.totalDurationDecimal);
    return acc;

    
  }, {});
  // Convert grouped data into an array of objects for rendering
  export const calculateTotalMinutesWithoutBreak = (reports) => {
    const groupedData = groupedDataByDate(reports); // Get grouped data
    
    const summarizedItems = Object.entries(groupedData).map(([reportDate, totalDurationDecimal]) => {
      // Adjust total hours by reducing 0.5 if it exceeds 6
     
      const adjustedDuration = totalDurationDecimal > 6 ? totalDurationDecimal - 0.5 : totalDurationDecimal;
      return { reportDate, totalDurationDecimal: adjustedDuration };
    });
 
      // Step 3: Calculate the sum of all totalDuration values


      return  summarizedItems.reduce((sum, item) => sum + item.totalDurationDecimal, 0);

  };
export const displayTimeInDecimalAndHoursMinutes = (timeStr) => {
  return `${convertTimeToDecimal(timeStr)} (${timeStr})`;
};

// Detailed report headers
export const detailedReportHeaders = [
  { label: 'תאריך', key: 'reportDate' },
  { label: 'שם מדווח', key: 'name' },
  { label: 'פרויקט', key: 'projectName' },
  { label: 'פעילות', key: 'activity' },
  { label: 'זמן התחלה', key: 'start_time' },
  { label: 'זמן סיום', key: 'end_time' },
  { label: 'משך פעילות', key: 'totalDuration' },
  { label: 'שעות לחיוב', key: 'totalDurationDecimal' },
  { label: 'תיאור', key: 'description' },
  { label: 'האם קיים קובץ', key: 'file' },
];

export const missingReportsHeaders = [
  { label: 'שם עובד', key: 'workerName' },
  { label: 'מחלקה', key: 'departmentName' },
  { label: 'חוסרים', key: 'missingDatesCount' },
];

// Summarized report headers
export const getSummarizedHeaders = (sortingOption) => [
  { label: getSortLabel(sortingOption), key: 'sorted_key' },
  { label: 'סה"כ חלקי', key: 'totalDuration' },
];

// Get sort label based on sorting option
export const getSortLabel = (sortingOption) =>
  ({
    byDate: 'לפי תאריך',
    byUser: 'לפי שם',
    byActivity: 'לפי פעילות',
  }[sortingOption] || '');
